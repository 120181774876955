import React from 'react'
import { MyBrainProvider } from './Brain'

export const wrapRootElement = ({ element }) => (
    <MyBrainProvider>{element}</MyBrainProvider>
)

//below code is needed for page transitions to work
export const shouldUpdateScroll = () => {
    return false;
};

export const onRenderBody = ({ setHeadComponents }) => {
    setHeadComponents([
      <meta
        key="apple-mobile-web-app-capable"
        name="apple-mobile-web-app-capable"
        content="yes"
      />,
      <meta
        key="apple-mobile-web-app-status-bar-style"
        name="apple-mobile-web-app-status-bar-style"
        content="black-translucent"
      />,
      <meta
        key="theme-color"
        name="theme-color"
        content="#FCFCFC" 
      />
    ]);
  };