import React from 'react';
import { useContext } from 'react';
import { createContext } from 'react';
import useNetlifyIdentity from './src/functions/useNetlifyIdentity';
import { useState } from 'react';

const defaultState = {
  data: {},
}

export const MyBrain = createContext(defaultState)
export const MyBrainProvider = ({ children }) => {



  const {
    authReady,
    user,
    error,
    login,
    logout,
    loginFailed,
    cloudRunToken,
    generateCloudRunToken,
    JWT,
    handleGoogleLogin
  } = useNetlifyIdentity()

// const authReady = true;


  const contextValues = {


    authReady,
    user,
    error,
    login,
    logout,
    loginFailed,
    cloudRunToken,
    generateCloudRunToken,
    JWT,
    handleGoogleLogin

  }

  return <MyBrain.Provider value={contextValues}>{children}</MyBrain.Provider>
}

export const useBrain = () => {
  const context = useContext(MyBrain)
  if (context === undefined || context === null) {
    throw new Error('useBrain must be called within Provider')
  }
  return context
}
